import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Layout } from "../components/common";
import { processHtml } from "../utils/processHtml";

import HeaderIllu from "../assets/illustrations/pages/jobs.svg";

const Container = styled.div``;

class Jobs extends Component {
  render() {
    const { data } = this.props;
    const { html, plaintext } = data.ghostPage;
    const { htmlX, jumbotronData } = processHtml(html, plaintext);

    return (
      <Layout
        title={data.ghostPage.title}
        subtitle={jumbotronData.subtitle}
        meta={{
          title: data.ghostPage.meta_title,
          description: data.ghostPage.meta_description,
          canonical: data.ghostPage.url,
          featureImage: data.ghostPage.feature_image
        }}
        jumbotronVariant="jobs"
        illustrations={<img className="smaller-illustration" alt="jobs" src={HeaderIllu} />}
      >
        <Container className="container">{htmlX}</Container>
      </Layout>
    );
  }
}

Jobs.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default Jobs;

export const pageQuery = graphql`
  query GhostJobsQuery {
    ghostPage(slug: { in: "we-are-hiring" }) {
      html
      plaintext
      title
      meta_title
      meta_description
      url
      feature_image
    }
  }
`;
